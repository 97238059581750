import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Doctors from "./Pages/Foods";
import About from "./Pages/About";
import PrivacyAndCookies from "./Pages/PrivacyAndCookies";
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import Loading from "./Components/Loading";

function loadGTM() {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-KBBF6XL7";
  script.async = true;
  document.head.appendChild(script);
}


function App() {
   const [user, setUser] = useState("");
   const [loading, setLoading] = useState(false);
   const { t } = useTranslation();
   const [language, setLanguage] = useState("en");
  useEffect(() => {
    loadGTM();
  }, []);

  function fetchAndSendIP() {
    setLoading(true);
    axios
      .post("https://hotpokerroom.com/api/setLanguage")
      .then((res) => {
        const { message } = res.data;
        setLoading(false);
        setUser(message);
      })
      .catch((error) => console.error("Error with backend:", error));
  }

  useEffect(() => {
    fetchAndSendIP();
  }, []);

  return (
    <div className="App">
      <Router basename="/">
        <Navbar t={t} language={language} setLanguage={setLanguage}/>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Reject"
          cookieName="mySiteCookieConsent"
          style={{
            background: "black",
            border: "1px solid gray",
            width: "30%",
            zIndex: "1000",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "baseline",
            right: "0px",
            position: "fixed",
            bottom: "0px",
          }}
          buttonStyle={{
            color: "white",
            fontSize: "16px",
            background: "green",
            margin: "5px",
            padding: "8px",
          }}
          declineButtonStyle={{
            color: "white",
            fontSize: "16px",
            background: "grey",
            margin: "5px",
            padding: "8px",
          }}
          expires={150}
          enableDeclineButton
          onAccept={() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "consent_given",
            });
          }}
          onDecline={() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "consent_declined",
            });
          }}
        >
          This message indicates that the website uses cookies, which are small
          data files stored on your device. By accepting cookies, the site can
          remember your preferences and provide a more personalized and
          efficient browsing experience. This helps improve overall
          functionality and convenience while using the site.
        </CookieConsent>

        <Routes>
          <Route path="/" element={<Home user={user} loading={loading} />} />
          <Route path="/terms-and-conditions" element={<Legal />} />
          <Route path="/etiquette" element={<Doctors />} />
          <Route path="/about" element={<About user={user} />} />
          <Route
            path="/privacy-policy"
            element={<PrivacyAndCookies />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer user={user} />
      </Router>
    </div>
  );
}

export default App;
