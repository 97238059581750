import logo2 from "../Assets/logo1.webp";
import logo001 from "../Assets/logo3.8b8f18396ddbad2c5f85.webp";
import logo02 from "../Assets/logo02.webp";
import logo04 from "../Assets/logo01.webp";
import logo008 from "../Assets/image.webp";
import logo from "../Assets/logo00.webp";
import logo011 from "../Assets/image11.webp";
import logo06 from "../Assets/logo06.webp";
import logo07 from "../Assets/logo07.webp";
import food1 from "../Assets/0_0 (1).webp";
import food2 from "../Assets/0_0 2.webp";
import food3 from "../Assets/0_1 (1).webp";
import food4 from "../Assets/0_1.webp";
import food5 from "../Assets/0_2.webp";
import food6 from "../Assets/0_2 (1).webp";
import logoMon01 from "../Assets/logo/punter.svg";
import logoMon02 from "../Assets/logo/platin.svg";
import logoMon03 from "../Assets/betandplay.svg";
import logoMon04 from "../Assets/logo/betflare.svg";
import logoMon05 from "../Assets/logo/verde.png";
import logoMon06 from "../Assets/logo/instant.svg";
import logoMon07 from "../Assets/logo/lucky.svg";
import logoMon08 from "../Assets/logo/destina.png";
import logoMon09 from "../Assets/logo/hitspin.svg";
import logoMon10 from '../Assets/logo/felix.svg'
import logoMon11 from '../Assets/logo/vinil.png'


export const data = [
  //1
  {
    id: 1,
    logo: logo001,
    address: "The Square Shopping Centre, Tallaght, Dublin 24, Dublin, Ireland",
    phone: "01 862 2601",
    email: "info@expocasino.ie",
    score: "9.8",
    link: "https://expo.casino/?utm_source=HeyDublin",
  },
  //2
  {
    id: 2,
    logo: logo2,
    address: "The Arena Centre Whitetown Way Tallaght Dublin 24",
    phone: " 087 990 4586",
    email: "j.p.poker@hotmail.com",
    score: "9.6",
    link: "https://jppoker.ie/",
  },
  // 3
  {
    id: 3,
    logo: logo02,
    address: "South Anne Street, Dublin 2, Dublin, Ireland",
    phone: "01 703 0600",
    email: "info@thesportingemporium.com    ",
    score: "9.5",
    link: "https://www.thesportingemporium.com/",
  },
  //4
  {
    id: 4,
    logo: logo008,
    address: "3 Fairview Strand, Fairview, Dublin 3, D03 AY94    ",
    phone: "089 4900187 ",
    email: "dublinpokerclub@gmail.com    ",
    score: "9.5",
    link: "https://www.dublinpokerclub.com/",
  },
  //5
  {
    id: 5,
    logo: logo011,
    address: "63 Lower Dorset Street,  Dublin 1, Dublin, Ireland",
    phone: "01 830 6764",
    email: "info@gamcare.org.uk",
    score: "8.8",
    link: "https://d1casino.ie/",
  },

  //6
  {
    id: 6,
    logo: logo04,
    phone: "091 589199    ",
    email: "i4acescasino2020@gmail.com",
    address: "19 Dominick Street Upper Galway, Ireland",
    score: "8.9",
    link: "https://www.the4aces.ie/",
  },

  //7
  {
    id: 7,
    logo: logo06,
    phone: "071 915 0515",
    email: "contact@adelaidecasino.ie",
    address:
      "Ted Nealon Rd, Adelaide Street, Abbeyquarter North, Sligo, Ireland",
    score: "8.4",
    link: "http://www.adelaidecasino.ie/",
  },
  //8
  {
    id: 8,
    logo: logo,
    phone: "01 845 3488",
    email: "info@westburyclub.com",
    address: "The Westbury Club, An Faiche, Malahide, Co. Dublin, Ireland",
    score: "8.9",
    link: "https://www.westburyclub.com/index.php",
  },
  //9
  {
    id: 9,
    logo: logo07,
    phone: "01 840 5565",
    email: "info@trumpsplaza.com",
    address: "10-11 FORSTER WAY, SWORDS, DUBLIN, K67NY61, IRELAND.",
    score: "8.1",
    link: "https://www.trumpsplaza.com/",
  },
];

export const foods = [
  //1
  {
    id: 1,
    img: food1,
    name: "Rule 1: Hit and Run",
    title:
      "The most unethical move in poker, known as angle shooting, encompasses any action aimed at deliberately deceiving other players. This can involve violating the rules of the game or breaching unwritten rules of etiquette. Angle shooting is generally frowned upon in the game, as it involves attempting to gain an unfair advantage over other players. Examples include acting out of turn, concealing or manipulating bet amounts, making fake bets, and declaring an action verbally but not following through with it. Angle shooting is considered an unacceptable tactic both within the written rules of poker and the unwritten code of conduct. ",
  },
  //2
  {
    id: 2,
    img: food2,
    name: "Rule 2: Don’t Talk About the Hand While it’s Going On",
    title:
      " Avoid words that could influence your opponents' actions. For instance, if the player to your left folds before the flop and you raise with A♠ T♥, and the flop comes with three spades, if the player on your left bangs the table saying I would've flopped it!, then your chance of hitting a fourth spade on the turn or river decreases. Players might discuss and comment on hands, but you shouldnt be among those commentators. If you are about to make a decision about a hand and you are going to make the final call, it is okay to engage in conversation, but it is generally better not to discuss hands during the game.",
  },
  // 3
  {
    id: 3,
    img: food3,
    name: "Rule 3: Don’t Slow Roll",
    title:
      "Slow rolling is one of the most disrespectful behaviors in poker. It involves a player intentionally taking a long time to make a decision despite knowing they hold strong cards. It's often done to annoy or mock opponents and can be infuriating for players. For example, when you go all-in, your opponent deliberately takes a long time to think before revealing their hand, even though they already know you've lost. Such behavior is frowned upon and unethical. ",
  },
  //4
  {
    id: 4,
    img: food4,
    name: "Rule 4: Avoid Angle Shooting",
    title:
      "One of the most cunning and frowned-upon behaviors in poker is angle shooting, which involves breaking the rules to deceive other players. It encompasses any move aimed at violating either the official rules or the unwritten norms of etiquette in order to gain an unfair advantage. Angle shooting tactics often provoke strong reactions because they're seen as attempts to exploit the game unfairly. For example, acting out of turn or concealing large bet amounts are common examples of angle shooting. Even though bluffing and deception are integral parts of the game, angle shooting is universally rejected both by written rules and by the unwritten codes of conduct.      ",
  },
  //5
  {
    id: 5,
    img: food5,
    name: "Rule 5: Act in a Timely Manner",
    title:
      "Timing is crucial in poker. Taking a reasonable amount of time to consider your decisions, especially in big pots or during the final stages of a tournament, is perfectly normal. However, generally speaking, making quick decisions when the action is on you is important. Adhering to the waiting rules of other players at the poker table is also essential. In most poker rooms, players can 'call the clock' on someone taking too long. If you have a strong hand and want to bluff your opponent, it's acceptable to take a little extra time, but it's important not to overdo it. In live cash games, there may not be a time bank, so it's usually best to act promptly.      ",
  },
  //6
  {
    id: 6,
    img: food6,
    name: "Rule 6: Pay Attention",
    title:
      " In an average full-ring  game, around 25-30 hands are played per hour. However, the pace can slow down due to distracting behaviors of players, such as phone or tablet usage, wearing headphones, or engaging in off-topic conversations. Patience is key, but players can be disappointed due to distracting behaviors from others. ",
  },
];

export const monData = [
 
 
  {
    id: 1,
    logo: logoMon03,
    name: "BetAndPlay",
    score:9.8,
    email: "support@rollino.co",
    bonus: "7.000€ oder 6 BTC+ 400 Freispiele",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2087",
    popular:true
  },
  {
    id: 2,
    logo: logoMon01,
    name: "MrPunter",
    score:9.8,
    //phone: "https://www.luckydreams.com/support",
    email: "support@luckydreams.com",
    bonus: "100% bis zu 500€   + 200 FS",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2089",
    popular:true
  },
  {
    id: 3,
    logo: logoMon04,
    popular:true,
    name: " BetFlare ",
    score:9.5,
    phone: "+442038870422",
    email: " kontakt@888.se",
    bonus: "250% bis zu €2000 + 300 FS für die ersten drei Einlagen    ",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2090",
  },
  {
    id: 4,
    logo: logoMon06,
    score:9.5,
    name: "InstantCasino ",
   // phone: "https://www.letslucky.com/support",
    email: "support@letslucky.com",
    bonus: "200% Willkommensbonus bis zu 7.500 € + 10% Cashback    ",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2091",
  },
  {
    id: 5,
    logo: logoMon02,
    name: "PlatinCasino ",
    //phone: "1-888-426-3661",
    email: "poker@betonlinecs.ag",
    score:9.4,
    bonus: "10.000€ Willkommenspaket + 450 FREISPIELE",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2092",
  },
  {
    id: 6,
    logo: logoMon05,
    name: "VerdeCasino ",
    score:9.2,
   // phone: "1-888-843-9027",
    email: "poker@sportsbettingcs.ag",
    bonus: "Bonus 1.200 € + 220 FS",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2093",
  },
  {
    id: 7,
    logo: logoMon07,
    score:9.1,
    name: "LuckyCircus ",
    //phone: "https://www.justcasino.com/support",
    email: "support@justcasino.com",
    bonus: "Hol Dir Dein Willkommenspaket 10.000€ + 450 Freispiele    ",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2094",
  },
  {
    id: 8,
    logo: logoMon08,
    score:9.9,
    name: "Destinobet ",
    //phone: "https://nordscasino.com/templates/contact_us",
    email: 'support@nordscasino.com',
    bonus: "",
    link: "",
  },
  {
    id: 9,
    logo: logoMon09,
    score:9.5,
    name: "Hit'n'Spın ",
    //phone: " 020-81 91 00 ",
    email: "info@spelpaus.se",
    bonus: "800 € + 200 FS Für die Einzahlung von 20 €  ",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2096",
  },
  {
    id: 10,
    logo: logoMon10,
    score:9.2,
    name: "FelıxSpın ",
    //phone: " 020-81 91 00 ",
    email: "info@spelpaus.se",
    bonus: "bis zu 750€ + 350 Freispiele   ",
    link: "https://rampaffiliates.media-412.com/click?pid=43&offer_id=2098",
  },
  {
    id: 11,
    logo: logoMon11,
    score:9.7,
    name: "Vynil",
    //phone: " 020-81 91 00 ",
    email: "info@spelpaus.se",
    bonus: "100% bis zu 500€  200FS + 1 Bonus Crab ",
    link: "https://vnl.xarvilo.com/?mid=157405_1468236",
  },
];

