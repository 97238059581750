
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/5416F4.webp";
import LazyLoad from "react-lazyload";
import i18n from "../Scripts/i18n";
import azerbaijanFlag from "../Assets/en.png";
import seFlag from "../Assets/german.svg";

function Navbar({ language, setLanguage, t }) {
  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  const openNav = () => {
    setNav(!nav);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n
      .changeLanguage(lng)
      .then(() => {
        setLanguage(lng);
        setIsOpen(false);
        console.log("Language changed to:", lng);
      })
      .catch((error) => {
        console.error("Error changing language:", error);
      });
  };

  return (
    <div className="navbar-section">
      <div className="navbar-title">
        <Link to="/" className="logo-link">
          <LazyLoad once>
            <img src={logo} alt="logos" />
          </LazyLoad>
        </Link>
      </div>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links">
            {t("navbar.home")}
          </Link>
        </li>
        <li>
          <Link to="/about" className="navbar-links">
            {t("navbar.about")}
          </Link>
        </li>
        <li>
          <Link to="/etiquette" className="navbar-links">
            {t("navbar.etiquette")}
          </Link>
        </li>
        <li>
          <Link to="/terms-and-conditions" className="navbar-links">
            {t("navbar.terms")}
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" className="navbar-links">
            {t("navbar.privacy")}
          </Link>
        </li>
        
        {/* Desktop Language Dropdown */}
        <div className="desktop-language-selector">
          <div onClick={toggleDropdown} className="language-selected">
            <span>{language === "en" ? "EN" : "GE"}</span>
            <img 
              src={language === "en" ? azerbaijanFlag : seFlag} 
              alt="Selected language" 
              className="flag-icon"
            />
          </div>
          {isOpen && (
            <ul className="language-dropdown">
              <li onClick={() => changeLanguage("en")}>
                <span>EN</span>
                <img src={azerbaijanFlag} alt="Azerbaijan flag" className="flag-icon" />
              </li>
              <li onClick={() => changeLanguage("ge")}>
                <span>GE</span>
                <img src={seFlag} alt="German flag" className="flag-icon" />
              </li>
            </ul>
          )}
        </div>
      </ul>

      <Link className="navbar-btn" to="/about">
        {t("navbar.button")}
      </Link>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              {t("navbar.home")}
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/about">
              {t("navbar.about")}
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/etiquette">
              {t("navbar.etiquette")}
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/terms-and-conditions">
              {t("navbar.terms")}
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/privacy-policy">
              {t("navbar.privacy")}
            </Link>
          </li>
          
          {/* Mobile Language Selector */}
          <div className="mobile-language-selector">
            <div 
              className={`language-option ${language === "en" ? "active" : ""}`}
              onClick={() => changeLanguage("en")}
            >
              <span>EN</span>
              <img src={azerbaijanFlag} alt="Azerbaijan flag" className="flag-icon" />
            </div>
            <div 
              className={`language-option ${language === "ge" ? "active" : ""}`}
              onClick={() => changeLanguage("ge")}
            >
              <span>GE</span>
              <img src={seFlag} alt="German flag" className="flag-icon" />
            </div>
          </div>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon icon={faBars} onClick={openNav} className="hamb-icon" />
      </div>
    </div>
  );
}

export default Navbar;
