import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { data, monData } from "../Scripts/reviews";
import "../Styles/Info.css";
import LazyLoad from "react-lazyload";
import arrow from "../Assets/icons8-arrow-80.png";
import { useTranslation } from "react-i18next";

// Loading Modal Component
const LoadingModal = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
      <div className="bg-black/20 p-8 rounded-lg border border-gray-700">
        <div className="text-center space-y-6">
          <div className="relative w-16 h-16 mx-auto">
            <div className="absolute w-16 h-16 animate-ping rounded-lg bg-orange-500 opacity-20"></div>
            <div className="absolute w-16 h-16 animate-pulse rounded-lg bg-orange-500 opacity-40"></div>
            <div className="relative w-16 h-16 rounded-lg bg-gradient-to-tr from-orange-500 to-yellow-400 shadow-xl">
              <div className="absolute inset-0.5 bg-black/20 rounded-lg"></div>
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-2xl font-bold bg-gradient-to-r from-orange-500 to-yellow-400 bg-clip-text text-transparent">
              Loading...{" "}
            </p>
            <p className="text-gray-400 text-sm animate-pulse">Wait...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function Info({ user }) {
  const itemsToMap = user === "user" ? monData : data;
  const [currentDate, setCurrentDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const now = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = now.toLocaleDateString("en-GB", options);
    setCurrentDate(formattedDate);
  }, []);

  const handleRedirect = (url, e) => {
    e.preventDefault();
    setIsLoading(true);

    // TikTok Pixel Code
    (function (w, d, t) {
      // Parantez eklendi
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
        "holdConsent",
        "revokeConsent",
        "grantConsent",
      ];
      ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      };
      ttq.load = function (e, n) {
        var r = "https://analytics.tiktok.com/i18n/pixel/events.js";
        var o = n && n.partner;
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = r;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        n = document.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = r + "?sdkid=" + e + "&lib=" + t;
        e = document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n, e);
      };
      ttq.load("CT85OE3C77U3MQBEQUV0");
      ttq.page();
    })(window, document, "ttq"); // Parantez eklendi

    // Yönlendirme için kısa bir bekleme
    setTimeout(() => {
      window.location.href = url;
    }, 500);
  };

  return (
    <div className="info-section" id="services">
      <LoadingModal isOpen={isLoading} />

      <div className="info-title-content">
        <h3 className="info-title">
          <span>
            {t("info.title")} {user === "user" ? "Germany" : " Ireland"}
          </span>
        </h3>
        {user === "user" ? (
          <p className="info-description-mont">
            {t("info.time")} : {currentDate}. <br />
            {t("info.text-mont")}
          </p>
        ) : (
          <p className="info-description">{t("info.text")}</p>
        )}
      </div>

      <div className="info-cards-content">
        {itemsToMap?.map((card) => (
          <a
            key={card.id}
            href={card.link}
            onClick={(e) => handleRedirect(card.link, e)}
            className="info-cards none pointer"
            style={{ backgroundColor: user === "user" ? "black" : "initial" }}
          >
            {card?.popular && user === "user" && (
              <span className="popular">Most Popular</span>
            )}
            <div className="info-card-icon">
              <span className="cardId">{card.id}</span>
              <LazyLoad height={200} once>
                <img
                  src={card.logo}
                  alt="logo"
                  style={{
                    padding: "30px",
                    objectFit: "contain",
                  }}
                />
              </LazyLoad>
            </div>
            <div className="content">
              <div className="card-content">
                {user === "user" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        className="info-card-title"
                        style={{
                          fontWeight: "600",
                          color: "orange",
                          fontSize: "30px",
                        }}
                      >
                        {card.score} /
                      </p>

                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "orange" }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "orange" }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "orange" }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "orange" }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "orange" }}
                      />
                    </div>

                    <p className="gradient-text">
                      {t(`bonuses.${card.id - 1}.bonus`)}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {" "}
                      {t("info.email")} : {card.email}
                    </p>
                    <p>
                      {t("info.phone")} : {card.phone}
                    </p>
                  </>
                )}
                {user !== "user" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                  </>
                ) : null}
              </div>
              <div className="card-content cont-1">
                <div className="visit-button">
                  <a
                    href={card.link}
                    className="none"
                    rel="noreferrer"
                    target="_blank"
                    onClick={(e) => handleRedirect(card.link, e)}  // handleRedirect ekledim

                  >
                    {" "}
                    <button>
                      {t("info.visit")} <img src={arrow} alt="arrow" />
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="card-content cont-2">
              {user !== "user" ? (
                <p className="info-card-description">
                  {t("info.location")}
                  {card.address}
                </p>
              ) : null}
              <div className="visit-button">
                <a
                  href={card.link}
                  onClick={(e) => handleRedirect(card.link, e)}
                  className="none"
                  rel="noreferrer"
                >
                  <button>
                    {user === "user" ? "få bonus" : "Visit Here"}
                    <img src={arrow} alt="arrow" />
                  </button>
                </a>
              </div>
            </div>
          </a>
        ))}
      </div>
      {user === "user" && (
        <div className=" text-mont">
          <article
            style={{
              marginTop: "30px",
              background: "rgba(0, 0, 0, 0.5)",
              padding: "20px",
              fontSize: "18px",
            }}
          >
            <p>{t("montText.article.paragraph1")}</p>
            <h1>{t("montText.header")}</h1>
            <p>{t("montText.article.paragraph2")}</p>
            <p>{t("montText.article.paragraph3")}</p>
            <p>{t("montText.article.paragraph4")}</p>
            <p>{t("montText.article.paragraph5")}</p>
            <p>{t("montText.article.paragraph6")}</p>
            <p>{t("montText.article.paragraph7")}</p>

            <section>
              <h2>{t("montText.article.subheader1")}</h2>
              <p>{t("montText.article.paragraph8")}</p>
              <p>{t("montText.article.paragraph9")}</p>
              <p>{t("montText.article.paragraph10")}</p>
              <p>{t("montText.article.paragraph11")}</p>
              <p>{t("montText.article.paragraph12")}</p>
            </section>

            <section>
              <h2>{t("montText.article.subheader2")}</h2>
              <p>{t("montText.article.paragraph13")}</p>
              <p>{t("montText.article.paragraph14")}</p>
              <p>{t("montText.article.paragraph15")}</p>
            </section>

            <section>
              <h2>{t("montText.article.subheader3")}</h2>
              <p>{t("montText.article.paragraph16")}</p>
              <p>{t("montText.article.paragraph17")}</p>
              <p>{t("montText.article.paragraph18")}</p>
              <p>{t("montText.article.paragraph19")}</p>
              <p>{t("montText.article.paragraph20")}</p>
              <p>{t("montText.article.paragraph21")}</p>
              <p>{t("montText.article.paragraph22")}</p>
            </section>

            <section>
              <h2>{t("montText.article.section1.header")}</h2>
              <p>{t("montText.article.section1.paragraph1")}</p>

              <h2>{t("montText.article.section2.header")}</h2>
              <p>{t("montText.article.section2.paragraph1")}</p>

              <h2>{t("montText.article.section3.header")}</h2>
              <p>{t("montText.article.section3.paragraph1")}</p>

              <h2>{t("montText.article.section4.header")}</h2>
              <p>{t("montText.article.section4.paragraph1")}</p>
            </section>

            <section>
              <h2>{t("montText.article.section5.header")}</h2>
              <>
                <p>{t("montText.article.section5.method1")}</p>
                <p>{t("montText.article.section5.method2")}</p>
                <p>{t("montText.article.section5.method3")}</p>
                <p>{t("montText.article.section5.method4")}</p>
              </>
              <p>{t("montText.article.section5.paragraph1")}</p>
            </section>

            <section>
              <h2>{t("montText.article.section6.header")}</h2>
              <p>{t("montText.article.section6.paragraph1")}</p>
            </section>

            <section>
              <h3>{t("montText.article.section8.header")}</h3>
              <p>{t("montText.article.section8.paragraph1")}</p>
            </section>
          </article>
        </div>
      )}
    </div>
  );










}

export default Info;
